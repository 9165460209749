<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="Noitem=Noitem.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="AdminDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="AdminDataSelect(Noitem)"></el-button>
            </div>
            <label>{{$t('All.料号')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: ''
    }
  },
  created () {
    this.User = this.$store.state.Login
  },
  mounted () {

  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname', 'NewIQCAdminNumberFun']),
    async AdminDataSelect (row) {
      const postdata = {
        Number: row,
        UserData: this.$store.state.Login
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCSelectadmin', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.QcCount()
          return this.$message.success(this.$t('All.数据不存在') + ',' + this.$t('All.即将进入编辑页面'))
        }
        loadingInstance.close()
        this.UserData(res.response)
        this.Addtab(this.$t('All.新IQC模版') + '-' + res.response.Number, 'NewIQCAdmin')
        this.RemoveTab(this.$t('All.新增') + this.$t('All.新IQC模版'))
      } catch (error) {
        loadingInstance.close()
        console.log(error)
      }
    },
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'IQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        this.NewIQCAdminNumberFun(res.response)
        loadingInstance.close()
        this.UserData(null)
        this.Productname(this.Noitem)
        this.Addtab(this.$t('All.新IQC模版') + '-' + res.response, 'NewIQCAdmin')
        this.RemoveTab(this.$t('All.新增') + this.$t('All.新IQC模版'))
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
